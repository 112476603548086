<template>
  <fair-layout :fair-id="fair.id" v-if="fair" title="Charlas Técnicas">
    <template v-slot:banner>
      <banner
        type="banner--admin"
        :title="fair.name"
        description=""
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="pt-3 px-7">
        <v-tabs>
          <v-tab>General</v-tab>
          <v-tab>Gestor de charlas</v-tab>
          <v-tab-item>
            <conference-settings :fair-id="fair.id"></conference-settings>
          </v-tab-item>
          <v-tab-item>
            <conference-manager :fair-id="fair.id" :fair-name="fair.name"></conference-manager>
          </v-tab-item>
        </v-tabs>
      </div>
    </template>
  </fair-layout>
</template>
<script>
import sessionMixin from '@/mixins/sessionMixin'
import ConferenceSettings from './components/conference-settings/ConferenceSettings'
import ConferenceManager from './components/conference-manager/ConferenceManager'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import notificationMixin from '../../../mixins/notificationMixin'
import loaderMixin from '../../../mixins/loaderMixin'
import crudServiceMixin from '../../../mixins/crudServiceMixin'
import FairLayout from '../../../layouts/FairLayout'
import Banner from '../../../components/banner/Banner'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: { Banner, FairLayout, ConferenceSettings, ConferenceManager },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      fair: null
    }
  },
  methods: {
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.id)
      this.fair = response.data
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin],
  name: 'Conferences'
}
</script>
