<template>
  <v-row justify="center">
    <v-dialog v-model="isOpen" max-width="500px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">Propietario de la reserva</span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Compañia propietaria"
                  vid="company"
                  rules="required"
                >
                  <v-select
                    v-model="model.companyId"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    class="py-1"
                    label="Empresa propietaria de la charla"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveConferenceBookingOwner()">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="onClose">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import crudServiceMixin from '../../../../../../mixins/crudServiceMixin'
import formOperationsMixin from '../../../../../../mixins/formOperationsMixin'
import notificationMixin from '../../../../../../mixins/notificationMixin'
import loaderMixin from '../../../../../../mixins/loaderMixin'
import { CONFERENCE_BOOKINGS_URL, FAIRS_URL } from '@/constants/ServicesConstants'

export default {
  data () {
    return {
      companies: [],
      isOpen: false,
      model: {
        companyId: null,
        fairId: null,
        id: null
      }
    }
  },
  methods: {
    async getCompanies (fairId) {
      const response = await this.get(FAIRS_URL + '/' + fairId + '/companies')
      this.companies = response.data
    },
    async open (fairId, conferenceBooking) {
      this.model.id = conferenceBooking.id
      this.model.companyId = conferenceBooking.user.booking?.companyId
      this.model.fairId = fairId
      await this.getCompanies(fairId)
      this.isOpen = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    onClose () {
      this.isOpen = false
      this.onClear()
      this.rejectPromise()
    },
    onClear () {
      this.model.companyId = ''
      this.$refs.form.reset()
    },
    saveConferenceBookingOwner () {
      this.executeWithFormValidation(async () => {
        this.update(CONFERENCE_BOOKINGS_URL + '/' + this.model.id + '/owners', this.model).then(() => {
          this.onClear()
          this.isOpen = false
          this.resolvePromise()
        })
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'ConferenceBookingOwnerModal'
}
</script>
