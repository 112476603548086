<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex align-center justify-space-between">
        <h3>Gestor de charlas y reserva de espacios</h3>
        <v-btn
          color="primary"
          outlined
          @click="generateReport"
          class="white--text text-none elevation-0">
          <v-icon left dark>
            fa-file-excel
          </v-icon>
          Exportar
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 py-0">
        <v-text-field
          label="Empresa"
          v-model="filters.company"
          outlined
          v-on:keyup.enter="searchConferenceBookings"
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="col-12 col-md-4 py-0">
        <v-select
          v-model="filters.keywords"
          :items="keywords"
          :multiple="true"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Palabras clave"
          @change="searchConferenceBookings"
        ></v-select>
      </div>
      <div class="col-12 col-md-4 py-0">
        <v-select
          v-model="filters.conferenceRoom"
          :items="conferenceRooms"
          item-text="name"
          item-value="id"
          outlined
          dense
          label="Salon"
          @change="searchConferenceBookings"
        ></v-select>
      </div>
      <div class="col-12 col-md-4 py-0">
        <date-picker label="Fecha" v-model="date" :is-range="true"></date-picker>
      </div>
      <div class="col-12 col-md-4 py-0">
        <v-select
          v-model="filters.status"
          :items="statuses"
          item-text="description"
          item-value="code"
          outlined
          dense
          label="Estado"
          @change="searchConferenceBookings"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 py-0 d-flex justify-end">
        <v-btn
          color="primary"
          @click="searchConferenceBookings"
          class="white--text text-none elevation-0 mr-2">
          <v-icon
            left
            dark>
            fa-search
          </v-icon>
          Buscar
        </v-btn>
        <v-btn
          color="default"
          @click="cleanFilters"
          class="text-none elevation-0">
          <v-icon
            left
            dark>
            fa-broom
          </v-icon>
          Limpiar
        </v-btn>
      </div>
    </div>
    <div class="row mt-6">
      <div class="col-12 py-0 d-flex justify-end">
        <v-btn
          color="primary"
          @click="onCreateConferenceBooking"
          class="white--text text-none elevation-0">
          <v-icon
            left
            dark>
            fa-plus
          </v-icon>
          Crear charla
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pt-1">
        <data-table ref="conferenceBookings" :params="filters" :url="conferenceBookingUrl"
                    :headers="conferenceManagerHeaders">

          <template v-slot:item.status="{ item }">
            <v-chip
              :color="bookingConferenceStatus[item.status].color"
              dark
            >
              {{ bookingConferenceStatus[item.status].description }}
            </v-chip>
          </template>

          <template v-slot:item.newStatus="{ item }">
            <v-select
              hide-details
              v-model="item.status"
              :items="statuses"
              item-text="description"
              item-value="code"
              outlined
              dense
              class="py-1"
              @change="onChangeNewStatus(item)"
            ></v-select>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2 elevation-0"
                  @click="onChangeConferenceBookingOwner(item)"
                  small
                  color="primary"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-people-arrows
                  </v-icon>
                </v-btn>
              </template>
              <span>Cambiar propietario de la charla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2 elevation-0"
                  @click="onEditConferenceBooking(item)"
                  small
                  color="primary"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar la charla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2 elevation-0"
                  @click="onDeleteConferenceBooking(item)"
                  small
                  color="primary"
                >
                  <v-icon
                    center
                    dark
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar la charla</span>
            </v-tooltip>
          </template>
        </data-table>
      </div>
    </div>
    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas cambiar el estado de la reserva?'"
      @onClose="onClose"
      @onConfirm="changeStatus"
      :is-active="isConfirmActive"
    />
    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas eliminar la reserva reserva?'"
      @onClose="onClose"
      @onConfirm="deleteConferenceBooking"
      :is-active="isDeleteConfirmActive"
    />
    <conference-booking-modal ref="conferenceBookingModal"/>
    <conference-booking-owner-modal ref="conferenceBookingOwnerModal"></conference-booking-owner-modal>
  </div>
</template>
<script>
import {
  CONFERENCE_BOOKINGS_URL,
  CONFERENCE_KEYWORDS_URL,
  CONFERENCE_ROOMS_URL
} from '@/constants/ServicesConstants'
import crudServiceMixin from '../../../../../mixins/crudServiceMixin'
import formOperationsMixin from '../../../../../mixins/formOperationsMixin'
import notificationMixin from '../../../../../mixins/notificationMixin'
import loaderMixin from '../../../../../mixins/loaderMixin'
import DatePicker from '@/components/date-picker/DatePicker'
import DataTable from '../../../../../components/data-table/DataTable'
import conferenceManagerHeaders from './data/ConferenceManagerHeaders'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import ConferenceBookingOwnerModal from './components/ConferenceBookingOwnerModal'
import BOOKING_CONFERENCE_STATUS from '../../../../../constants/BookingConferenceStatusConstants'
import ConferenceBookingModal from '@/views/private/conference-bookings/components/ConferenceBookingModal'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    DataTable,
    ConfirmDialog,
    ConferenceBookingOwnerModal,
    ConferenceBookingModal
  },
  data () {
    return {
      bookingConferenceStatus: BOOKING_CONFERENCE_STATUS,
      conferenceBookingId: null,
      conferenceBookingUrl: CONFERENCE_BOOKINGS_URL,
      conferenceKeywordsUrl: CONFERENCE_KEYWORDS_URL,
      conferenceManagerHeaders,
      date: null,
      filters: {
        date: null,
        fairId: this.fairId,
        keywords: null,
        company: null,
        conferenceRoom: null,
        startDate: null,
        status: null
      },
      conferenceRoomsUrl: CONFERENCE_ROOMS_URL,
      conferenceRooms: [],
      keywords: [],
      newStatus: null,
      isConfirmActive: false,
      isDeleteConfirmActive: false,
      statuses: []
    }
  },
  methods: {
    async changeStatus () {
      try {
        this.showLoader()
        this.isConfirmActive = false
        await this.put(this.conferenceBookingUrl + '/' + this.newStatus.id + '/statuses', {
          status: this.newStatus.status
        })
        this.hideLoader()
        this.showSuccess('Cambio de estado exitoso')
      } catch (error) {
        const response = error.response
        this.showError(response.data.error)
      } finally {
        this.hideLoader()
        await this.searchConferenceBookings()
      }
    },
    cleanFilters () {
      this.filters.date = null
      this.filters.keywords = null
      this.filters.company = null
      this.filters.conferenceRoom = null
      this.filters.startDate = null
      this.filters.status = null
      this.date = null
      this.searchConferenceBookings()
    },
    async deleteConferenceBooking () {
      this.showLoader()
      await this.delete(this.conferenceBookingUrl, this.conferenceBookingId)
      this.isDeleteConfirmActive = false
      this.conferenceBookingId = null
      this.$refs.conferenceBookings.getDataFromApi()
      this.hideLoader()
    },
    async generateReport () {
      try {
        this.showLoader()
        const response = await this.get(this.conferenceBookingUrl + '/exports', {
          responseType: 'blob',
          params: {
            fairId: this.fairId
          }
        })
        const fairName = (this.fairName) ? this.fairName.trim().replace(/\s/g, '_') : 'feria'
        const currentDate = moment(new Date()).format('DD_MM_YYYY_HH_mm_ss')
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Reporte_Charlas_Tecnicas_' + fairName + '_' + currentDate + '.xlsx')
        link.click()
      } catch (error) {
        this.showError()
      } finally {
        this.hideLoader()
      }
    },
    async getConferenceBookingStatuses () {
      const response = await this.get(this.conferenceBookingUrl + '/statuses')
      this.statuses = response.data
    },
    async getConferenceRooms () {
      const response = await this.get(this.conferenceRoomsUrl + '/all', { params: { fairId: this.fairId } })
      this.conferenceRooms = response.data
    },
    async getKeywords () {
      const response = await this.get(this.conferenceKeywordsUrl + '/all', { params: { fairId: this.fairId } })
      this.keywords = response.data
    },
    onChangeNewStatus (value) {
      this.isConfirmActive = true
      this.newStatus = value
    },
    onClose () {
      this.isConfirmActive = false
      this.newStatus = null
      this.isDeleteConfirmActive = false
      this.conferenceBookingId = null
      this.searchConferenceBookings()
    },
    onCreateConferenceBooking () {
      this.$refs.conferenceBookingModal.open(null, this.fairId, true).then(() => {
        this.$refs.conferenceBookings.getDataFromApi()
      }).catch((error) => {
        throw error
      })
    },
    onDeleteConferenceBooking (item) {
      this.isDeleteConfirmActive = true
      this.conferenceBookingId = item.id
    },
    onEditConferenceBooking (item) {
      this.$refs.conferenceBookingModal.open(item, this.fairId, true).then(() => {
        this.$refs.conferenceBookings.getDataFromApi()
      }).catch((error) => {
        throw error
      })
    },
    onChangeConferenceBookingOwner (item) {
      this.$refs.conferenceBookingOwnerModal.open(this.fairId, item).then(() => {
        this.$refs.conferenceBookings.getDataFromApi()
      }).catch((error) => {
        throw error
      })
    },
    searchConferenceBookings () {
      this.$refs.conferenceBookings.getDataFromApi(1)
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  mounted () {
    this.getConferenceBookingStatuses()
    this.getConferenceRooms()
    this.getKeywords()
  },
  name: 'ConferenceManager',
  props: {
    fairId: {
      default: null,
      type: Number
    },
    fairName: {
      default: null,
      type: String
    }
  },
  watch: {
    date (val) {
      if (val.from && val.to) {
        this.filters.date = []
        this.filters.date[0] = val.from
        this.filters.date[1] = val.to
      }
      this.searchConferenceBookings()
    }
  }
}
</script>
